import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Logo from "../../assets/images/logo_new.svg";
import Google from "../../assets/images/icons8-google.svg";
import FaceBook from "../../assets/images/icons8-facebook.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { login, socialLogin } from "../../api/authApi";
import Loader from "../../components/loader/loader";
import { Helmet } from "react-helmet";
import BackBtn from "../../assets/svg/BackBtn";
import { successAlert } from "../../lib/utils";
// import { FacebookLoginButton } from "react-social-login-buttons";
function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [logData, setLogdata] = useState({
    email: "",
    password: "",
  });
  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });

  const handleChangeInput = (event) => {
    let { name, value } = event.target;

    setLogdata({
      ...logData,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: "",
    });
  };

  const loginAfterSignupMutation = useMutation(login, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.data) {
        localStorage.setItem("tm-email", data?.data?.user_email);
        successAlert(data?.data.message);
        navigate("/otp");
      }
      return;
    },
    onError: (error) => {
      setLoading(false);
      if (error?.response?.data?.errors) {
        toast.error(error.response.data.errors, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        return;
      }
    },
  });

  const onLoginHandler = () => {
    if (validateForm()) {
      setLoading(true);
      loginAfterSignupMutation.mutate(logData);
    }
  };

  const socialMutation = useMutation(socialLogin, {
    onSuccess: (data) => {
      setLoading(false);
      if (data) {
        window.location.replace(data?.data);
      }
      return;
    },
    onError: (error) => {
      console.log("error", error);
      setLoading(false);
    },
  });

  const handleSocialLogin = (name) => {
    setLoading(true);
    socialMutation.mutate(name);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoading(true);
      loginAfterSignupMutation.mutate(logData);
    }
  };

  const validateForm = () => {
    const formKeys = Object.keys(logData);
    let valid = true;
    let grt_validation = {};
    for (let i = 0; i < formKeys.length; i++) {
      const c_validate = validate(formKeys[i], logData[formKeys[i]]);
      if (c_validate !== true) {
        valid = false;
        grt_validation[formKeys[i]] = c_validate;
      } else {
        grt_validation[formKeys[i]] = "";
      }
    }
    if (!valid) {
      setValidation(grt_validation);
    }
    return valid;
  };

  const validate = (name, value) => {
    if (name === "email") {
      var pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        if (!pattern.test(value)) {
          return "Please enter valid email address";
        }
      } else {
        return "Please enter your email address";
      }
    } else if (name === "password") {
      if (!value) {
        return "Please enter password";
      }
    }
    return true;
  };

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <title>The Merchant - Login</title>
        <meta property="og:title" content="The Merchant - Login" />
        <link rel="canonical" href="https://eltaajir.com/login" />
      </Helmet>
      <section className="login-page">
        <div className="logoin-box-shadow">
          <Row className="p-0 m-0">
            <Col md={6} lg={8} className="p-0 m-0">
              <div className="btn-wrapper">
                <button className="back-tab" onClick={() => navigate("/")}>
                  <BackBtn /> Back to main page
                </button>
              </div>
              <div className="login-img">
                <Image
                  src={require("../../assets/images/add-logoin-img.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={6} lg={4} className="p-0 m-0">
              <div className="login-form">
                <div className="logo-login">
                  <Image src={Logo} onClick={() => navigate("/")} />
                </div>
                <p>Login into your account</p>
                <Form className="log-in-input">
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label className="form-new-lable">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.email ? "error-add" : ""
                      }`}
                      placeholder="Enter Your Email"
                      type="email"
                      name="email"
                      value={logData.email}
                      onChange={handleChangeInput}
                      onKeyDown={handleKeyDown}
                    />
                    <span className="error-new">{validation.email}</span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupPassword">
                    <Form.Label className="form-new-lable">Password</Form.Label>
                    <Form.Control
                      className={`input-box ${
                        validation.password ? "error-add" : ""
                      }`}
                      placeholder="Enter Your Password"
                      type="password"
                      name="password"
                      value={logData.password}
                      onChange={handleChangeInput}
                      onKeyDown={handleKeyDown}
                    />
                    <span className="error-new">{validation.password}</span>
                  </Form.Group>
                  <div className="forget-password">
                    <Link to="/forgot-password">Forgot password ?</Link>
                  </div>
                  <Button
                    className="login-btn"
                    variant="primary"
                    onClick={() => onLoginHandler()}
                  >
                    Login
                  </Button>
                </Form>

                <div className="separator"></div>
                <Button
                  className="sign-up-btn"
                  variant="primary"
                  type="submit"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </Button>
                <div className="separator"></div>


                <Button
                  className="facebook-btn"
                  variant="primary"
                  onClick={() => handleSocialLogin("facebook")}
                >
                  <Image src={FaceBook} />
                  Log in from Facebook
                </Button>
                <Button
                  className="google-btn"
                  variant="primary"
                  onClick={() => handleSocialLogin("google")}
                >
                  <Image src={Google} />
                  Log in from Google
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
